import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from "dayjs/plugin/updateLocale";
import isBetweenPlugin from "dayjs/plugin/isBetween";

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});
dayjs.updateLocale('km', {
    weekStart: 1,
});
dayjs.extend(isBetweenPlugin);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(process.env.REACT_APP_TIMEZONE);

export default dayjs.tz;